(function () {
  if (window.ticketonNew) return;

  const widgetHostURL = '$VITE_URL';

  const iframeContainer = document.createElement('div');
  const iframe = document.createElement('iframe');

  iframeContainer.appendChild(iframe);

  const applePayType = 'newPage';

  const closeWidget = () => {
    document.body.classList.remove('additionalBodyStyle');

    document.body.removeChild(iframeContainer);
  };

  const redirectToMainSite = (url) => {
    window.location.href = url;

    document.body.removeChild(iframeContainer);
  };

  iframeContainer.setAttribute('id', 'widgetContainer');

  iframe.setAttribute('id', 'widgetFrame');
  iframe.setAttribute('allow', 'clipboard-read; clipboard-write');

  Object.assign(iframe.style, {});

  const styleLink = document.createElement('link');
  styleLink.setAttribute('rel', 'stylesheet');
  styleLink.setAttribute('href', `${widgetHostURL}/frame.css`);
  document.querySelector('head').appendChild(styleLink);

  // const frameCloseButton = document.createElement("button");
  // frameCloseButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:#fff;opacity:0;}.cls-2{fill:#231f20;}</style></defs><title>close</title><g id="Layer_2" data-name="Layer 2"><g id="close"><g id="close-2" data-name="close"><rect class="cls-1" width="24" height="24" transform="translate(24 24) rotate(180)"/><path class="cls-2" d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></g></g></g></svg>`;
  // frameCloseButton.setAttribute("id", "frameCloseButton");
  //
  // frameCloseButton.onclick = () => {
  //   openAlertWindow();
  // };

  window.addEventListener('message', (event) => {
    if (event.data === 'frameClosingWithoutMessage') {
      closeWidget();
    }

    if (
      typeof event.data === 'string'
      && event.data.startsWith('frameRedirectMainPageMessage')
    ) {
      redirectToMainSite(
        event.data.replace('frameRedirectMainPageMessage', ''),
      );
    }

    if (
      typeof event.data === 'string'
      && event.data.startsWith('successPaymentRedirect')
    ) {
      redirectToMainSite(
        event.data.replace('successPaymentRedirect', ''),
      );
    }
  });

  window.ticketonNew = {};

  let lastMetaViewport = '';

  const openProcedure = () => {
    document.body.classList.add('additionalBodyStyle');
    const content = document.querySelector('meta[name="viewport"]');

    lastMetaViewport = content.getAttribute('content');

    content.setAttribute(
      'content',
      'width=device-width, initial-scale=1.0, maximum-scale=1.0',
    );
  };

  const getDefaultUrlParams = () => `
  applePayType=${applePayType}
  &parentLocationHref=${encodeURIComponent(window.location.href)}
  &parentOrigin=${window.origin}
  &timestamp=${Date.now()}`;

  Object.assign(window.ticketonNew, {
    openEvent: (event, city, date) => {
      openProcedure();

      console.info('OpenEvent handler');
      // eslint-disable-next-line max-len
      iframe.src = `${widgetHostURL}?${getDefaultUrlParams()}&event=${event}&city=${city}`;
      document.body.appendChild(iframeContainer);
      // document.body.appendChild(frameCloseButton);
    },
    openPlace: (place, date) => {
      openProcedure();

      console.info('OpenPlace handler');
      // eslint-disable-next-line max-len
      iframe.src = `${widgetHostURL}?${getDefaultUrlParams()}&place=${place}`;
      document.body.appendChild(iframeContainer);
      // document.body.appendChild(frameCloseButton);
    },
    openShow: (show, params) => {
      const parsedParams = params ? JSON.parse(params) : {};

      console.log('openShow integration script');
      openProcedure();

      // eslint-disable-next-line max-len
      const url = new URL(
        `${widgetHostURL}
        ?${getDefaultUrlParams()}&show_id=${show}`,
      );
      Object.entries(parsedParams).forEach(([key, value]) => {
        url.searchParams.set(key, value);
      });

      console.info('OpenShow handler');
      iframe.src = url.toString();
      document.body.appendChild(iframeContainer);
    },
  });

  window.ticketonNew.openEventFromNewWidget = (event, place) => {
    openProcedure();

    iframe.src = `${widgetHostURL}
    ?${getDefaultUrlParams()}&event=${event}`;
    document.body.appendChild(iframeContainer);
    // document.body.appendChild(frameCloseButton);
  };

  window.ticketonNew.openPayAlmatyMarathon = (
    show_id,
    external_id,
    some_param,
  ) => {
    openProcedure();

    // eslint-disable-next-line max-len
    iframe.src = `${widgetHostURL}?${getDefaultUrlParams()}&show_id=${show_id}&external_id=${external_id}`;
    document.body.appendChild(iframeContainer);
    // document.body.appendChild(frameCloseButton);
  };

  window.ticketonNew.closeFrame = () => {
    document
      .querySelector('meta[name="viewport"]')
      .setAttribute('content', lastMetaViewport);
    document.body.removeChild(iframeContainer);
    document.body.removeChild(iframeContainer);
  };
}());
